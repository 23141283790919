import { FaCalendarAlt, FaUserFriends, FaPencilAlt, FaPlayCircle, FaTrophy, FaShoppingBag, FaMoon, FaGhost, FaCalendarTimes } from 'react-icons/fa';

const currentEventsData = [
    {
        title: () => (<><h3 className="text-3xl text-left  font-semibold mb-4 text-red-800"><FaUserFriends className="inline mr-2 text-red-800" />Forum des Associations</h3></>),
        description: () => (
          <p className="text-gray-700">Découvrez le Badminton Club Eragny au Forum des Associations ! Une occasion unique de rencontrer nos passionnés, essayer ce sport dynamique et trouver la formule qui vous convient. Que vous soyez novice ou expert, jeune ou moins jeune, venez partager notre enthousiasme pour le badminton !</p>
        ),
        startDate: new Date(),
        endDate: new Date("2024-09-08"),
        button: {
            text: "Y aller",
            section: "acces"
        }
    },
    {
      title: () => (<><h3 className="text-3xl text-left  font-semibold mb-4 text-red-800"><FaPencilAlt className="inline mr-2 text-red-800" />Inscriptions ouvertes pour la saison 2024-2025</h3></>),
      description: () => (
        <p className="text-gray-700">Embarquez pour une saison captivante de badminton ! Que vous visiez la compétition ou le loisir, notre club vous offre un environnement stimulant pour progresser et vous épanouir. Rejoignez notre communauté dynamique et vivez votre passion du volant !</p>
      ),
      startDate: new Date("2024-08-08"),
      endDate: new Date("2024-10-01"),
      button: {
        text: "S'inscrire maintenant",
        section: "inscription",
        tab: "modalite"
      },
      image: require("../images/home/events/BaD_CAMPAGNE_2024_Signature_Mail_580x135.jpg"),
    },
    {
        title: () => (<><h3 className="text-3xl text-left  font-semibold mb-4 text-red-800"><FaPlayCircle className="inline mr-2 text-red-800" />Reprise du Badminton</h3></>),
        description: () => (
          <p className="text-gray-700">Le BCE vous souhaite une rentrée énergique ! Dès le lundi 9 septembre à 20h, tous les créneaux s'ouvrent à vous. Préparez vos raquettes, affûtez vos réflexes, et rejoignez-nous sur les courts pour une saison palpitante de défis et de progrès !</p>
        ),
        startDate: new Date(),
        endDate: new Date("2024-09-15"),
        button: {
            text: "Consulter les créneaux",
            section: "creneaux"
        }
    },
    {
      title: () => (<><h3 className="text-3xl text-left  font-semibold mb-4 text-red-800"><FaTrophy className="inline mr-2 text-red-800" />Tournoi interne de Bienvenue</h3></>),
      description: () => (
        <p className="text-gray-700">Le dimanche 6 octobre 2024, plongez dans l'ambiance festive de notre Tournoi de Bienvenue ! Une journée inoubliable pour tisser des liens, défier vos nouveaux partenaires de jeu et découvrir les projets excitants qui rythmeront notre saison. Convivialité et esprit sportif garantis !</p>
      ),
      startDate: new Date("2024-09-09"),
      endDate: new Date("2024-10-06"),
    },
    {
        title: () => (<><h3 className="text-3xl text-left  font-semibold mb-4 text-red-800"><FaShoppingBag className="inline mr-2 text-red-800" />Intervention Lardesport</h3></>),
        description: () => (
          <p className="text-gray-700">Le 6 octobre 2024, lors de notre tournoi interne, ne manquez pas l'expertise de Jimmy Jouhannet de Lardesport ! Découvrez les dernières innovations en équipement, bénéficiez de conseils personnalisés et optimisez votre jeu. Une opportunité unique d'allier technologie et performance sur le court !</p>
        ),
        startDate: new Date("2024-09-09"),
        endDate: new Date("2024-10-06"),
        button: {
          text: "Voir leur site",
          url: "https://www.lardesports.com/"
        },
    },
    {
        title: () => (<><h3 className="text-3xl text-left  font-semibold mb-4 text-red-800"><FaMoon className="inline mr-2 text-red-800" />Téléthon Blackminton</h3></>),
        description: () => (
          <p className="text-gray-700">Vivez une expérience inédite le vendredi 29 novembre 2024 à 20h ! Notre Blackminton pour le Téléthon vous plonge dans un univers de badminton fluorescent. Relevez des défis uniques, partagez des fous rires et soutenez une noble cause. Une soirée magique où sport et solidarité brillent dans l'obscurité !</p>
        ),
        startDate: new Date("2024-11-11"),
        endDate: new Date("2024-11-29"),
    },
    {
      title: () => (<><h3 className="text-3xl text-left  font-semibold mb-4 text-orange-600"><FaGhost className="inline mr-2 text-orange-600" />Soirée Halloween</h3></>),
      description: () => (
        <>
          <p className="text-sm text-gray-700">Du 16 au 18 octobre, le BCE se transforme en repaire de créatures fantastiques ! Enfilez vos costumes les plus effrayants et rejoignez-nous sur les courts pour des matchs ensorcelés. Dracula, Frankenstein, ou loup-garou, tous sont les bienvevis pour cette célébration sportive et terrifiante !</p>
          <p className="text-sm text-gray-700 mb-2">Bonbons et surprises vous attendent. Préparez-vous à frissonner de plaisir !</p>
          <p className="text-sm text-gray-700"><FaCalendarAlt className="inline mr-2 text-orange-600" />Mercredi 16, Minimes Cadets de Dahn 18H - 20H</p>
          <p className="text-sm text-gray-700"><FaCalendarAlt className="inline mr-2 text-orange-600" />Jeudi 17, jeu libre adultes 20h - 22H</p>
          <p className="text-sm text-gray-700"><FaCalendarAlt className="inline mr-2 text-orange-600" />Vendredi 18, Poussins Benjamins de Dahn 18H30 - 19H30</p>
          <p className="text-sm text-gray-700 mb-2"><FaCalendarAlt className="inline mr-2 text-orange-600" />Vendredi 18, jeu libre adultes 19H30 - 22H</p>
          <p className="text-sm font-bold text-gray-700 mb-2">Rendez-vous au gymnase de la Butte pour des volants ensorcelés !</p>
        </>
      ),
      button: {
        text: "S'y rendre",
        section: "acces"
      },
      startDate: new Date("2024-10-12"),
      endDate: new Date("2024-10-18"),
      image: require("../images/home/events/halloween.png"),
  },
  //Annulation : 
  {
    title: () => (
        <><h3 className="text-2xl text-left font-semibold mb-4 text-red-800">
            <FaCalendarTimes className="inline mr-2 text-red-800" />Annulation - Session Adulte et Jeune du 29 Novembre 2024
        </h3></>
    ),
    description: () => (
        <p className="text-gray-700">
            En raison du Repas de Gala du Téléthon organisé par la mairie, le gymnase est réquisitionné. La session de badminton pour adultes et jeunes prévue ce jour-là est annulée. Nous vous remercions pour votre compréhension et espérons vous retrouver lors de nos prochaines séances !
        </p>
    ),
    startDate: new Date("2024-11-01"),
    endDate: new Date("2024-11-29")
  },
  {
      title: () => (
          <><h3 className="text-2xl text-left font-semibold mb-4 text-red-800">
              <FaCalendarTimes className="inline mr-2 text-red-800" />Annulation - Session Adulte du 19 janvier 2025 et jeu libre du 20 janvier 2025
          </h3></>
      ),
      description: () => (
          <p className="text-gray-700">
              En préparation du tournoi Vétérans, la session adulte du vendredi 19 janvier ainsi que le jeu libre du samedi 20 janvier 2025 sont annulés. Nous vous invitons à suivre les prochaines dates pour ne pas manquer nos futures sessions sportives.
          </p>
      ),
      startDate: new Date("2025-01-01"),
      endDate: new Date("2025-01-20")
  },
  {
      title: () => (
          <><h3 className="text-2xl text-left font-semibold mb-4 text-red-800">
              <FaCalendarTimes className="inline mr-2 text-red-800" />Annulation - Session Jeune et Adulte du 16 mars 2025 et jeu libre du 17 mars 2025
          </h3></>
      ),
      description: () => (
          <p className="text-gray-700">
              En raison du tournoi double organisé par le club de Crossminton, les sessions jeunes et adultes du vendredi 16 mars, ainsi que le jeu libre du samedi 17 mars 2025 sont annulés. Profitez-en pour venir encourager les participants au tournoi et partager cet événement sportif avec nous !
          </p>
      ),
      startDate: new Date("2025-03-01"),
      endDate: new Date("2025-03-17")
  },
  {
      title: () => (
          <><h3 className="text-2xl text-left font-semibold mb-4 text-red-800">
              <FaCalendarTimes className="inline mr-2 text-red-800" />Annulation - Session Adulte du 20 juin 2025 et jeu libre du 21 juin 2025
          </h3></>
      ),
      description: () => (
          <p className="text-gray-700">
              Le gymnase sera occupé pour le tournoi des TPR organisé par le club le 20 juin 2025. De ce fait, la session adulte ainsi que le jeu libre du samedi sont annulés. Nous espérons vous voir nombreux pour soutenir les participants et partager avec nous cette journée sportive !
          </p>
      ),
      startDate: new Date("2025-06-01"),
      endDate: new Date("2025-06-21")
  }
];

const historyEvents = [
  {
    date: "1998",
    title: "Fondation du club",
    description: "Création du Badminton Club Eragny par un groupe de passionnés. Ce club est devenu un lieu de rencontre incontournable pour les amateurs de badminton, forgeant une communauté soudée autour de valeurs sportives et conviviales.",
    status: "Passé"
  }
];

export { currentEventsData, historyEvents };